<template>
	<div
		class="field"
		:class="{ 'is-flex is-justify-content-space-between': isInline }"
		:disabled="disabled"
		@click.prevent.stop="changeStatus"
		@keypress.space.prevent="changeStatus"
		@keypress.enter.prevent="changeStatus"
		:data-testid="dataTestid + '-field'"
	>
		<ws-form-label
			v-if="label"
			:id="id"
			:disabled="disabled"
			:error="error"
			:is-inline="isInline"
			:optional="optional"
			:tooltip="tooltip"
			:tooltip-position="tooltipPosition"
		>
			{{ label }}
		</ws-form-label>
		<div class="control inputField is-flex">
			<label
				class="checkbox switcher"
				:class="{
					disabled: disabled,
					'is-primary': type === 'primary',
					'is-info': type === 'info',
					'is-success': type === 'success',
					'is-warning': type === 'warning',
					'is-danger': type === 'danger'
				}"
				v-bind="{ disabled: disabled }"
				:checked="checked"
				tabindex="0"
			>
				<input
					type="checkbox"
					v-model="checked"
					:name="name"
					:data-testid="dataTestid"
					:disabled="disabled"
				/>
				<span class="is-align-self-center"> </span>
				<p class="is-align-self-center ml-2">
					<slot>
						{{ checked ? descOn : descOff }}
					</slot>
				</p>
			</label>
		</div>
		<p class="help" style="margin-bottom: 0" v-if="labelUnderInput">
			{{ labelUnderInput }}
		</p>
		<p class="help is-danger" v-if="!!error">{{ error }}</p>
	</div>
</template>

<script>
import { generateRandomId } from "@/helpers/functions.helper.js";
export default {
	name: "WsFormToggle",

	emits: ["update:modelValue", "change"],

	props: {
		id: {
			type: String,
			default: () => {
				return generateRandomId();
			}
		},
		modelValue: {
			type: Boolean,
			default: false
		},
		label: {
			type: [String, null],
			default: null
		},
		type: {
			type: String,
			default: "primary",
			validation: function (value) {
				return [
					"primary",
					"info",
					"success",
					"warning",
					"danger"
				].includes(value);
			}
		},
		isInline: {
			type: Boolean,
			default: false
		},
		optional: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: null
		},
		dataTestid: {
			type: String,
			default: "form-switch"
		},
		name: {
			type: String,
			default: null
		},
		tooltip: {
			type: [String, null],
			default: null
		},
		tooltipPosition: {
			type: [String, null],
			default: "top"
		},
		labelUnderInput: {
			type: String,
			default: null
		},
		descOn: {
			type: String,
			default: ""
		},
		descOff: {
			type: String,
			default: ""
		},
		error: {
			type: String,
			default: null
		}
	},

	watch: {
		modelValue: function (newStatus) {
			this.checked = newStatus;
		}
	},

	data() {
		return {
			checked: this.modelValue
		};
	},

	methods: {
		changeStatus() {
			if (this.disabled) {
				return;
			}

			this.$nextTick(() => {
				this.checked = !this.checked;
				this.$emit("update:modelValue", this.checked);
				this.$emit("change", this.checked);
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.switcher {
	display: flex;
	align-content: center;
	cursor: pointer !important;

	&.disabled {
		cursor: not-allowed !important;
		opacity: 0.5;
	}

	input {
		display: none;

		+ span {
			transition: all 0.4s;
			display: inline-block;
			height: 1.5rem;
			width: 3rem;
			min-width: 3rem;
			background: $color-grey-300;
			border-radius: 2rem;
			box-shadow: inset 1px 1px 1px $black-o10;
			position: relative;

			&::before {
				transition: all 0.4s;
				content: "";
				display: block;
				position: absolute;
				height: 1.1rem;
				width: 1.1rem;
				left: 0.2rem;
				top: 0.2rem;
				border-radius: 1.1rem;
				background: $white;
				box-shadow: 1px 1px 1px $black-o10;
			}
		}

		&:checked {
			+ span {
				&::before {
					left: calc(3rem - 0.2rem - 1.1rem);
				}
			}
		}
	}
}
.switcher.is-primary input:checked + span {
	background: $color-primary-500;
}
.switcher.is-success input:checked + span {
	background: $color-success-500;
}
.switcher.is-danger input:checked + span {
	background: $color-danger-500;
}
.switcher.is-info input:checked + span {
	background: $color-info-500;
}
.switcher.is-warning input:checked + span {
	background: $color-warning-500;
}

.inlineLabel {
	line-height: 40px;
	margin: 0;
	width: 200px !important;
	min-width: 200px;
	max-width: 200px;
}
.inputField {
	width: 100%;
	margin: 0;
	line-height: 40px;
}
</style>
